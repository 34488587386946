/*
----------------
index.jsx
----------------
This is the entry point for our app.

Webpack starts with this file and pulls in all imports to create the bundle.js.
*/
import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import ReactDOM from "react-dom";
import App, { history } from './app'
import { launchpadInit } from 'launchpad'
import { run_preflight } from 'launchpad/devops/pre-flight'
import { render } from 'react-dom'

// do a sanity check before proceeding
run_preflight()

launchpadInit(<App />, 'root', { history: history })

const root = document.getElementById("root");
ReactDOM.render(<App />, root);

// Parcel 2 - Hot Reloading
// https://parceljs.org/features/development/#hot-reloading

if (module.hot) {
  module.hot.dispose(function (data) {
    // module is about to be replaced.
    // You can save data that should be accessible to the new asset in `data`
    data.updated = Date.now();
  });

  module.hot.accept(function (getParents) {
    // module or one of its dependencies was just updated.
    // data stored in `dispose` is available in `module.hot.data`
    let { updated } = module.hot.data;
  });
}